<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="1200">
    <v-card :loading="loading">
      <v-system-bar windo>
        {{ title }}<v-spacer /><v-btn icon @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-card-text class="ma-0 pa-0">
        <v-container>
          <v-row>
            <v-col>
              <v-card outlined class="mb-4">
                <v-system-bar>Anschrift</v-system-bar>
                <v-list dense>
                  <v-list-item>
                    <v-text-field
                      label="Institution"
                      v-model="item.instName"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      class="mr-2"
                      label="Vorname 1"
                      v-model="item.firstName1"
                    ></v-text-field>
                    <v-text-field
                      class="ml-2"
                      label="Nachname 1"
                      v-model="item.lastName1"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      class="mr-2"
                      label="Vorname 2"
                      v-model="item.firstName2"
                    ></v-text-field>
                    <v-text-field
                      class="ml-2"
                      label="Nachname 2"
                      v-model="item.lastName2"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      label="Anrede"
                      v-model="item.salutation"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      label="Adresszeile 1"
                      v-model="item.address1"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      label="Adresszeile 2"
                      v-model="item.address2"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      class="mr-2"
                      label="PLZ"
                      v-model="item.postcode"
                    ></v-text-field>
                    <v-text-field
                      class="ml-2"
                      label="Ort"
                      v-model="item.town"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      label="Land"
                      v-model="item.country"
                    ></v-text-field>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card outlined class="mb-4">
                <v-system-bar>Kontakt</v-system-bar>
                <v-list dense>
                  <v-list-item>
                    <v-text-field
                      label="Telefon Privat"
                      v-model="item.phoneHome"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      label="Telefon Mobil"
                      v-model="item.phoneMobile"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      label="Telefon Arbeit"
                      v-model="item.phoneWork"
                    ></v-text-field>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined class="mb-4">
                <v-system-bar>Domäne</v-system-bar>
                <v-textarea v-model="item.domain" class="mx-4" />
              </v-card>
              <v-card outlined class="mb-4">
                <v-system-bar>Kommentar</v-system-bar>
                <v-textarea v-model="item.comments" class="mx-4" />
              </v-card>

              <v-card outlined>
                <v-system-bar>Weitere Angaben</v-system-bar>
                <v-card-text class="px-0 py-2">
                  <v-list dense>
                    <v-list-item>
                      <v-text-field
                        label="Beruf"
                        v-model="item.profession"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field
                        label="Erhalten"
                        v-model="item.received"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-switch
                        :label="item.privat ? 'Privat: ja' : 'Privat: nein'"
                        v-model="item.privat"
                        inset
                      ></v-switch>
                    </v-list-item>
                    <v-list-item>
                      <v-switch
                        :label="item.active ? 'Aktiv: ja' : 'Aktiv: nein'"
                        v-model="item.active"
                        inset
                      ></v-switch>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="close">Abbrechen</v-btn><v-spacer />
        <v-btn color="primary" text @click="save" :loading="saving"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      dialog: true,
      loading: false,
      item: { active: true },
      saving: false,
    };
  },
  computed: {
    isNew() {
      return this.id == 0;
    },
    title() {
      return this.isNew
        ? "neue Institution/Familie"
        : this.item.instName == null
        ? "Familie bearbeiten"
        : "Institution bearbeiten";
    },
  },
  methods: {
    cancel() {
      if (this.isNew) {
        this.$router.push({ name: "PlacementInstitutions" });
      } else {
        this.$router.push({
          name: "PlacementInstitutionDetail",
          params: { id: this.item.id },
        });
      }
    },
    close() {
      this.$router.push({
        name: "PlacementInstitutionDetail",
        params: { id: this.item.id },
      });
    },
    async fetchData() {
      this.loading = true;
      if (!this.isNew) {
        this.item = await this.apiGet({
          resource: "placement/institution",
          id: this.id,
        });
      }
      this.loading = false;
    },
    async save() {
      this.saving = true;
      if (this.isNew) {
        const newItem = await this.apiPost({
          resource: "placement/institution",
          data: this.item,
        });
        this.$router.push({
          name: "PlacementInstitutionDetail",
          params: { id: newItem.id },
        });
      } else {
        await this.apiPut({
          resource: "placement/institution",
          id: this.item.id,
          data: this.item,
        });
        this.$router.push({
          name: "PlacementInstitutionDetail",
          params: { id: this.item.id },
        });
      }
      this.$emit("updated");
      this.saving = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
